import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    toast(title, variant='info', icon='AlertCircleIcon', text='') {
      const props = {title}
      if (text) props.text = text
      if (icon) props.icon = icon
      if (variant) props.variant = variant
      this.$toast({
        component: ToastificationContent,
        props
      })
    },
    bpjsToast(metadata, variant='danger', icon='AlertTriangleIcon') {
      if (metadata?.code == 200) return true;
      this.toast('INFO RESPON DARI BPJS', variant, icon, metadata.message)
      return false;
    }
  }
}