import Vue from 'vue'

// axios
import axios from 'axios'
import xhr from 'axios/unsafe/adapters/xhr.js'
import Dexie from 'dexie'
import sha256 from 'crypto-js/sha256'
import { useAuthStore } from '@/pinia/auth'
import router from '../router'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const db = new Dexie('myDatabase')
db.version(1).stores({
  axiosCache: 'hash, result, expiresAt'
})

const CACHE_TTL = 2 * 60 * 1000 // 2 minutes
const cacheMethod = ['get']

const doNotCache = [
  'kunjungan',
  'kunjungan/\\d+',
  'obat/search',
  'summary',
  'antrian_ruangan',
  'pasien',
  'penerimaan/\\d+/detail',
  'opname',
  'buku-kas',
  'penerimaan/hutang',
  'tindakan',   // TODO: pagination dont invalidate properly
  'karyawan',   // TODO: pagination dont invalidate properly
  'Produsen',   // TODO: pagination dont invalidate properly
  'Merk',       // TODO: pagination dont invalidate properly
  'ProdukObat', // TODO: pagination dont invalidate properly
  'ruangan/smf',
  'ruangan/instalasi',
  'ruangan/dokter',
  'ruangan/medis',
  'ruangan/bed',
  'jadwal',
  'referensi/\\d+/\\d+(/ref_id)?/(enable|disable)',
  'satu-sehat',
]

const cacheAdapter = (defaultAdapter) => async (config) => {
  const { method, headers } = config
  let url = config.url
  const param = config.params
  url = url + (param ? `?${(new URLSearchParams(param)).toString()}` : '')

  // Request Logic
  const urlWithoutParams = url.split('?')[0]
  for (const item of doNotCache) {
    if (new RegExp(item).test(urlWithoutParams)) {
      // console.log('Not Caching', urlWithoutParams, 'because of', item)
      return defaultAdapter(config)
    }
  }

  if (!cacheMethod.includes(method)) {
    // console.log('Not Caching', urlWithoutParams, 'because of', method)
    return defaultAdapter(config)
  }

  if (headers['X-CACHE-INVALIDATE']) {
    // console.log(
    //   'Invalidating',
    //   urlWithoutParams,
    //   'because of',
    //   headers['X-CACHE-INVALIDATE']
    // )
    await invalidate(url)
    return defaultAdapter(config)
  }

  const hash = await digestMessage(url)
  const cached = await db.axiosCache.get({ hash })

  // if (cached) {
  //   console.log('cache found', url, 'Valid until', new Date(cached?.expiresAt))
  // } else {
  //   console.log('cache not found', url)
  // }

  if (cached && Date.now() <= (cached?.expiresAt ?? 0)) {
    const result = JSON.parse(await decompressString(cached.result))
    return {
      data: result,
      status: 200,
      statusText: 'OK',
      headers: {}, // Custom headers if needed
      config,
      request: null
    }
  }

  if (cached && Date.now() > cached.expiresAt) {
    try {
      await db.axiosCache.delete({ hash })
    } catch (error) {
      // Do nothing
    }
  }

  // Proceed with the request
  const response = await defaultAdapter(config)

  // Response Logic
  if (method === 'post' && url === '/api/v1/auth') {
    const authStore = useAuthStore()
    setToken(response.data.access_token)
    authStore.setToken({
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token
    })
    return response
  }

  if (method !== 'get') return response

  if (url.endsWith('logout')) {
    db.axiosCache.clear()
    return response
  }

  for (const item of doNotCache) {
    if (new RegExp(item).test(urlWithoutParams)) {
      return response
    }
  }

  if (!cacheMethod.includes(method)) {
    return response
  }

  if (response.headers['Cache-Control'] === 'no-cache') {
    return response
  }

  let ttl = CACHE_TTL
  if (headers.get('X-CACHE-TTL') || response.headers.get('X-CACHE-TTL')) {
    ttl =
      parseInt(
        headers.get('X-CACHE-TTL') || response.headers.get('X-CACHE-TTL'),
        10
      ) * 1000
  }

  const result = await compressString(response.data)
  const expiresAt = Date.now() + ttl
  if (response.data && response.status < 400) {
    // console.log('url', url, 'cached for', ttl, 'ms until', new Date(expiresAt))
    db.axiosCache.put({ hash, result, expiresAt }, hash)
  }

  return response
}

axiosIns.defaults.adapter = cacheAdapter(xhr)

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
      const authStore = useAuthStore()
      authStore.signOut()
      if (router.currentRoute?.name !== 'auth-login') {
        router.replace({ name: 'auth-login' })
      }
    }
    return Promise.reject(error)
  }
)

async function digestMessage(message) {
  return btoa(sha256(message))
}

async function compressString(inputData) {
  const input = JSON.stringify(inputData)
  const encoder = new TextEncoder()
  const data = encoder.encode(input)

  const cs = new CompressionStream('gzip')
  const writer = cs.writable.getWriter()
  writer.write(data)
  writer.close()

  const compressedStream = cs.readable
  const compressedArrayBuffer = await new Response(
    compressedStream
  ).arrayBuffer()
  const compressedData = new Uint8Array(compressedArrayBuffer)

  let binary = ''
  compressedData.forEach((byte) => {
    binary += String.fromCharCode(byte)
  })
  return btoa(binary)
}

async function decompressString(inputData) {
  const binaryString = atob(inputData)
  const compressedData = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    compressedData[i] = binaryString.charCodeAt(i)
  }
  const ds = new DecompressionStream('gzip')
  const writer = ds.writable.getWriter()
  writer.write(compressedData)
  writer.close()

  const decompressedStream = ds.readable
  const decompressedArrayBuffer = await new Response(
    decompressedStream
  ).arrayBuffer()
  const decoder = new TextDecoder()

  return decoder.decode(decompressedArrayBuffer)
}

export function setToken(token) {
  try {
    if (token === undefined) throw new Error('Token is undefined')
  } catch (e) {
    console.error(e)
  }
  axiosIns.defaults.headers.common.Authorization = 'Bearer ' + token
}

export async function invalidate(url) {
  const hash = await digestMessage(url)
  try {
    if (await db.axiosCache.get({ hash })) await db.axiosCache.delete({ hash })
  } catch (e) {
    // do nothing
  }
}

Vue.prototype.$http = axiosIns

export default axiosIns
