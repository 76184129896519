import sLocalStorage from '@core/utils/secure-storage'
import QRCode from 'qrcode'
export default {
  data() {
    return {
      ttd: null,
      userData: JSON.parse(sLocalStorage.getItem('userData')),
    }
  },
  created() {
    this.checkQr();
  },
  watch: {
    ttdQr() {
      this.checkQr();
    }
  },
  methods: {
    async checkQr() {
      if (!this.qrContent) return;
      try {
        this.ttd = await QRCode.toDataURL(this.qrContent);
      } catch (e) {
        this.ttd = null;
      }
    }
  }
}