<template>
  <b-modal
    :id="modalId"
    v-model="modalVisible"
    modal-class="modal-fullscreen modal-tambah-kunjungan"
    scrollable
    centered
    :static="true"
    :title="modalTitle"
    cancel-variant="outline-secondary"
    @close="closeModal"
  >
    <validation-observer ref="PendaftaranKunjungan">
      <b-form ref="formPendaftaranKunjungan" class="mt-1">
        <b-row>
          <b-col cols="6">
            <b-form-group label="Waktu Pendaftaran" label-cols-md="5">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Tanggal Pendaftaran"
              >
                <b-input-group>
                  <input-date-time v-model="dateTimeDaftar" :disabled="disabled" />
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <vue-autosuggest
              v-if="!pasien"
              v-model="selectedPasienText"
              :suggestions="filteredPasiens"
              :limit="10"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: 'Cari pasien menurut No.RM/No.KTP/No.BPJS/Nama',
                disabled: data !== null || disabled
              }"
              :get-suggestion-value="pasienSuggestionValue"
              @selected="pasienSelected"
              @keyup="onSearchPasien"
              @keydown="onSearchPasien"
            >
              <template slot-scope="{ suggestion }">
                <span v-if="search != '' && suggestion.item.formattedNama.toLowerCase().includes(search.toLowerCase())" class="my-suggestion-item">
                  {{ suggestion.item.formattedNama.substr(0, suggestion.item.formattedNama.toLowerCase().indexOf(search.toLowerCase())) }}<span v-if="suggestion.item.formattedNama.toLowerCase().includes(search.toLowerCase())" style="background-color: yellow;">{{ suggestion.item.formattedNama.substr(suggestion.item.formattedNama.toLowerCase().indexOf(search.toLowerCase()), search.length) }}</span>{{ suggestion.item.formattedNama.substr(suggestion.item.formattedNama.toLowerCase().indexOf(search.toLowerCase()) + search.length) }}
                </span>
                <span v-else class="my-suggestion-item">
                  {{ suggestion.item.formattedNama }}
                </span>
              </template>
            </vue-autosuggest>
            <b-form-input
              v-else
              :disabled="true"
              v-model="selectedPasienText"
            />
            <small v-if="pasienError" class="text-danger">{{
              pasienError
            }}</small>
          </b-col>
        </b-row>
        <b-row class="match-height mt-1">
          <b-col sm="12" md="6" xl="6">
            <b-card-text>
              <h5>Tujuan Ke :</h5>
              <b-row>
                <b-col lg="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Tujuan Kunjungan"
                    >
                      <v-select
                        v-model="tujuan.selectedInstalation"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="clearableVSelect"
                        label="name"
                        :options="instalations"
                        placeholder="Pilih Tujuan"
                        :state="errors.length > 0 ? false : null"
                        :disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Tujuan Unit"
                    >
                      <v-select
                        v-model="tujuan.selectedUnit"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="clearableVSelect"
                        label="name"
                        :options="units"
                        placeholder="Pilih Unit"
                        :disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Tujuan Ruangan"
                    >
                      <v-select
                        v-model="tujuan.selectedRuangan"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="clearableVSelect"
                        label="name"
                        :options="ruangans"
                        placeholder="Pilih Ruangan"
                        :disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Tujuan Dokter"
                    >
                      <v-select
                        v-model="tujuan.selectedDokter"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="clearableVSelect"
                        label="name"
                        :options="dokters"
                        placeholder="Pilih Dokter"
                        :disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="isRanap" lg="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Kode Reservasi"
                    >
                      <b-input-group>
                        <b-form-input
                          v-if="tujuan.kodeReservasi"
                          v-model="tujuan.kodeReservasi.nama_bed"
                          placeholder="Kode Reservasi"
                          :disabled="disabled"
                          readonly
                        />
                        <b-form-input
                          v-else
                          placeholder="Kode Reservasi"
                          :disabled="disabled"
                          readonly
                        />
                        <b-button
                          v-if="!disabled"
                          variant="outline-primary"
                          v-b-modal.reservasi-bedpanel-modal
                        >
                          Reservasi
                        </b-button>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="pakets.length" lg="12">
                  <b-form-group>
                    <v-select
                      v-model="tujuan.selectedPaket"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      label="name"
                      :options="pakets"
                      placeholder="Pilih Paket"
                      style="flex-grow: 1;"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-col>
          <b-col sm="12" md="6" xl="6">
            <b-card-text>
              <h5>Penjamin :</h5>
              <b-row>
                <b-col lg="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Penjamin / Asuransi"
                    >
                      <v-select
                        v-model="penjamin.selectedPenjamin"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="clearableVSelect"
                        label="title"
                        :options="$store.getters['masterdata/penjamin']"
                        placeholder="Pilih Penjamin"
                        :disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="isNotUmum" :lg="isBpjs ? 8 : 12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :rules="`${
                        (nomorKartuPenjaminRequired ? 'required' : '') +
                        (isBpjs ? '|min:13|max:13' : '')
                      }`"
                      name="Nomor Penjamin"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="penjamin.nomorKartuPenjamin"
                          placeholder="Nomor Kartu / Penjamin"
                          :disabled="disabled"
                        />
                        <b-input-group-append v-if="isBpjs">
                          <b-button variant="outline-success" @click="openModalBpjsNoka">
                            <icon icon="mdi:user" width="1rem" height="1rem" />
                          </b-button>
                          <b-button v-if="selectedPasien && selectedPasien.nik" variant="outline-success" @click="openModalBpjsNik">
                            <icon icon="mdi:user-card-details" width="1rem" height="1rem" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="isBpjs" lg="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Kelas Penjamin"
                      :disabled="disabled"
                    >
                      <v-select
                        v-model="penjamin.selectedKelasPenjamin"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="clearableVSelect"
                        label="title"
                        :options="
                          $store.getters['masterdata/penjaminKelas']
                        "
                        placeholder="Pilih Kelas Hak"
                        :disabled="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="12" v-if="false">
                  <b-form-group>
                    <b-form-input
                      v-model="penjamin.nomorSEP"
                      placeholder="Nomor Surat Eligibilitas Pasien (SEP)"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <b-form-group>
                    <b-form-input
                      v-model="penjamin.keterangan"
                      placeholder="Catatan/Keterangan"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <PanelRekamMedis ref="panelRekamMedis" :visible="isBpjs" :is-rajal="isRajal" :disabled="disabled" :value="rekamMedis" @input="rekamMedis = $event" />
        </b-row>
          <PanelKecelakaan ref="panelKecelakaan" :value="kecelakaan" :disabled="disabled" @input="kecelakaan = $event" />
        <b-row class="mt-1">
          <b-col sm="12" md="12" xl="12">
            <b-form-checkbox v-model="tanggungJawab.enabled" v-if="!disabled">
              <h5>Penanggung Jawab {{ tanggungJawab.enabled ? ':' : '' }}</h5>
            </b-form-checkbox>
            <h5 v-else>Penanggung Jawab</h5>
          </b-col>
          <b-col v-if="tanggungJawab.enabled" sm="12" md="12" xl="12">
            <b-card-text>
              <b-row>
                <b-col lg="6">
                  <b-form-group>
                    <v-select
                      v-model="tanggungJawab.selectedPasienRelation"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="clearableVSelect"
                      label="title"
                      :options="$store.getters['masterdata/shdk']"
                      placeholder="Hubungan dengan pasien"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <b-form-input
                      v-model="tanggungJawab.nama"
                      placeholder="Nama Keluarga tanpa Gelar"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select
                      v-model="tanggungJawab.jkSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="clearableVSelect"
                      label="title"
                      :options="$store.getters['masterdata/jk']"
                      placeholder="Jenis Kelamin"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select
                      v-model="tanggungJawab.pendidikanSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="clearableVSelect"
                      label="title"
                      :options="$store.getters['masterdata/pendidikan']"
                      placeholder="Pendidikan"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select
                      v-model="tanggungJawab.pekerjaanSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="clearableVSelect"
                      label="title"
                      :options="$store.getters['masterdata/pekerjaan']"
                      placeholder="Pekerjaan"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <b-form-group>
                    <b-form-input
                      v-model="tanggungJawab.alamat"
                      placeholder="Masukkan Alamat"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <b-form-input
                      v-model="tanggungJawab.telp"
                      placeholder="Ex. 08123456789"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <v-select
                      v-model="tanggungJawab.jenisKontakSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="clearableVSelect"
                      label="title"
                      :options="$store.getters['masterdata/jenisKontak']"
                      placeholder="Jenis Kontak"
                      :disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-col>
        </b-row>

        <b-overlay :show="showDialogConfirmation" no-wrap @shown="onShown">
          <template #overlay>
            <div
              v-if="processingSubmit"
              class="text-center p-3 text-secondary rounded"
            >
              <feather-icon icon="ClockIcon" size="36" />
              <div class="mb-2">
                Mohon tunggu ...
              </div>
              <b-progress
                min="1"
                max="100"
                :value="counterProgress"
                variant="secondary"
                height="12px"
                class="mx-n3"
              />
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <b-card-text class="font-weight-bolder" style="max-width: 300px;">
                Saya menjamin bahwa pasien sudah membaca dan menyetujui
                <a href="/documents/general-consent" target="_blank">Form Consent</a>
                pembukaan data di SATUSEHAT
              </b-card-text>
              <div class="d-flex justify-content-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mr-3"
                  @click="onConfirmSubmit(false)"
                >
                  Tidak
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="onConfirmSubmit(true)"
                >
                  YA
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </b-form>
    </validation-observer>
    <b-sidebar
      v-if="pasienBpjsVisible"
      id="sidebar-pasien-bpjs"
      sidebar-class="sidebar-lg"
      :visible="pasienBpjsVisible"
      bg-variant="white"
      shadow
      backdrop
      left
      :title="`Cek Pasien By ${bpjsPasienResponse.method}`"
      @change="(val) => pasienBpjsVisible = val"
    >
      <b-card no-body>
        <b-card-body>
          <!-- Alert for inactive patient -->
          <b-alert
            v-if="!bpjsPasienResponse.aktif"
            show
            variant="danger"
            class="mb-1 p-1"
          >
            <strong>Warning:</strong> status BPJS tidak aktif.
          </b-alert>

          <b-list-group flush>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Nama</strong></span>
              <span>{{ bpjsPasienResponse.nama }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>No. Kartu</strong></span>
              <span>{{ bpjsPasienResponse.noKartu }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>NIK</strong></span>
              <span>{{ bpjsPasienResponse.nik }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Hubungan Keluarga</strong></span>
              <span>{{ bpjsPasienResponse.hubunganKeluarga }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Jenis Kelamin</strong></span>
              <span>{{ bpjsPasienResponse.sex }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Tanggal Lahir</strong></span>
              <span>{{ bpjsPasienResponse.tglLahir }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Tgl Mulai Aktif</strong></span>
              <span>{{ bpjsPasienResponse.tglMulaiAktif }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Tgl Akhir Berlaku</strong></span>
              <span>{{ bpjsPasienResponse.tglAkhirBerlaku }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Provider</strong></span>
              <span>{{ bpjsPasienResponse.provider }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Provider Gigi</strong></span>
              <span>{{ bpjsPasienResponse.providerGigi }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Kelas</strong></span>
              <span>{{ bpjsPasienResponse.kelas }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Jenis Peserta</strong></span>
              <span>{{ bpjsPasienResponse.jenisPeserta }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Golongan Darah</strong></span>
              <span>{{ bpjsPasienResponse.golDarah }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>No. HP</strong></span>
              <span>{{ bpjsPasienResponse.noHp }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Status Aktif</strong></span>
              <span>{{ bpjsPasienResponse.statusAktif }}</span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <span><strong>Tunggakan</strong></span>
              <span>
                <template v-if="bpjsPasienResponse.tunggakan < 1">
                  {{ bpjsPasienResponse.tunggakan }}
                </template>
                <template v-else>
                  <strong class="text-danger">{{ formatMoney(bpjsPasienResponse.tunggakan) }}</strong>
                </template>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </b-card>
    </b-sidebar>


    <template #modal-footer>
      <b-button
        variant="outline-danger"
        @click="$bvModal.hide(modalId)"
      >
        Batal
      </b-button>
      <b-button v-if="!data" variant="warning" @click.prevent="resetForm">
        Reset
      </b-button>
      <b-button v-if="!data" variant="primary" @click.prevent="submitForm">
        Daftarkan
      </b-button>
    </template>
    <b-modal id="reservasi-bedpanel-modal" v-if="this.tujuan && this.tujuan.selectedRuangan">
      <panel-bed
        :ruangan-id="`${this.tujuan.selectedRuangan.id}`"
        :ruangan-name="`${this.tujuan.selectedRuangan.name}`"
        @selected="selectBed"
      />
    </b-modal>
  </b-modal>
</template>

<script>
import axiosIns from '@/libs/axios'
import Ripple from 'vue-ripple-directive'
import HasToast from '@core/mixins/ui/toast'
import HasMoney from '@core/mixins/ui/money'
import PanelKecelakaan from './panels/PanelKecelakaan.vue'
import PanelRekamMedis from './panels/PanelRekamMedis.vue'
import PanelBed from './panels/PanelBed.vue'
import { debounce } from 'lodash';

export default {
  directives: {
    Ripple
  },
  components: {
    PanelKecelakaan,
    PanelRekamMedis,
    PanelBed,
  },
  mixins: [HasToast, HasMoney],
  props: {
    pasien: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean
    },
    data: {
      type: Object,
      default: null
    },
    modalId: {
      type: String,
      default: 'modal-tambah-kunjungan'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      modalTitle: this.data
        ? 'Detail Pendaftaran'
        : 'Pendaftaran Kunjungan Baru',
      modalVisible: this.visible,
      rujukan: {
        pkkSelected: null,
        nomorRujukan: null,
        dateRujukan: this.$moment(now).format('YYYY-MM-DD'),
        dateRujukanFormatted: null,
        dokter: null,
        smf: null,
        diagnosa: null,
        selectedIcd: null
      },
      tujuan: {
        selectedInstalation: null,
        selectedUnit: null,
        selectedRuangan: null,
        // selectedSmf: null,
        selectedDokter: null,
        selectedPaket: null,
        kodeReservasi: null,
      },
      penjamin: {
        selectedPenjamin: null,
        nomorKartuPenjamin: null,
        selectedKelasPenjamin: null,
        nomorSEP: null,
        keterangan: null
      },
      kecelakaan: {
        jenis_kecelakaan: null,
        no_laporan_polisi: null,
        penjamin: null,
        keterangan: null,
        tanggal_kejadian: null,
        suplesi: null,
        no_sep_suplesi: null,
        kode_provinsi: null,
        kode_kabupaten: null,
        kode_kecamatan: null,
        status: null,
      },
      rekamMedis: {
        kunjungan_sakit: true,
        keluhan: null,
        tinggi_badan: null,
        berat_badan: null,
        lingkar_perut: null,
        imt: null,
        sistole: null,
        diastole: null,
        resp_rate: null,
        heart_rate: null,
      },
      tanggungJawab: {
        enabled: false,
        selectedPasienRelation: null,
        nama: null,
        jkSelected: null,
        pendidikanSelected: null,
        pekerjaanSelected: null,
        alamat: null,
        telp: null,
        jenisKontakSelected: null
      },
      nomorKartuPenjaminRequired: false,
      clearableVSelect: false,
      selectedTindakans: [],
      maxDateDaftar: today,
      dateDaftar: this.$moment(now).format('YYYY-MM-DD'),
      dateTimeDaftar: this.data ?
        this.$moment(this.data.tanggal_pendaftaran).format('YYYY-MM-DD HH:mm:ss') :
        this.$moment().format('YYYY-MM-DD HH:mm:ss'),
      dateDaftarFormatted: null,
      timeDaftar: this.$moment(now).format('HH:mm'),
      search: '',
      filteredPasiens: [],
      filteredIcds: [],
      instalations: [],
      units: [],
      ruangans: [],
      // smfs: [],
      dokters: [],
      pakets: [],
      selectedPasien: null,
      pasienError: null,
      pasiens: [],
      queryIcd: null,
      icd: [],
      provinces: [],
      cities: [],
      dristricts: [],
      tindakanPageLength: 5,
      searchTindakan: '',
      tindakans: [],
      tindakanTableHeader: [
        {
          label: 'checkbox',
          key: 'id',
          tdClass: 'w-10 text-align-center'
        },
        {
          label: 'tindakan',
          key: 'name'
        },
        {
          label: 'harga',
          key: 'formattedPrice'
        }
      ],
      pkkSuggest: {
        data: [
          {
            id: 1,
            name: 'RS. ABCD'
          },
          {
            id: 2,
            name: 'PUSKESMAS LKOPPASASA'
          }
        ]
      },
      filteredPKKOptions: [],
      showDialogConfirmation: false,
      processingSubmit: false,
      counterProgress: 1,
      interval: null,
      postedData: null,
      tempNoBpjs: '',
      pasienBpjsVisible: false,
      bpjsPasienResponse: {
        method: 'NIK',
        noKartu: '',
        nik: '',
        nama: '',
        hubunganKeluarga: '',
        sex: '',
        tglLahir: '',
        tglMulaiAktif: '',
        tglAkhirBerlaku: '',
        provider: '',
        providerGigi: '',
        kelas: '',
        jenisPeserta: '',
        golDarah: '',
        noHp: '',
        statusAktif: '',
        tunggakan: 0,
        aktif: false,
      },
    }
  },
  computed: {
    selectedPasienText: {
      get() {
        if (this.selectedPasien?.nama) {
          return `${this.selectedPasien?.norm} - ${this.selectedPasien?.nama}`
        }
        return this.selectedPasien?.norm
      },
      set(text) {
        const norm = text.split(' - ')[0]
        const nama = text.split(' - ')[1]
        this.selectedPasien = { norm, nama }
      }
    },
    isRajal() {
      if (!this?.tujuan?.selectedRuangan) return false;
      return this.tujuan.selectedRuangan.jenis_kunjungan === 1;
    },
    age() {
      if (!this.selectedPasien) return -1;
      return this.$moment().diff(this.selectedPasien.tanggal_lahir, 'years')
    },
    isBpjs() {
      return this.penjamin?.selectedPenjamin?.value === 2
    },
    isNotUmum() {
      return this.penjamin?.selectedPenjamin?.value !== 1
    },
    isRanap() {
      return this.tujuan?.selectedRuangan?.jenis_kunjungan === 3
    },
  },
  watch: {
    data() {
      this.dateTimeDaftar = this.$moment(this.data.tanggal_pendaftaran).format('YYYY-MM-DD HH:mm:ss')
      console.log(this.data.tujuan_pasien.kamar)
      this.tujuan.kodeReservasi = this.data.tujuan_pasien.kamar
    },
    penjamin: {
      handler(penjamin) {
        if (
          penjamin.selectedPenjamin &&
          penjamin.selectedPenjamin.title == 'Tanpa Asuransi / Umum'
        ) {
          this.nomorKartuPenjaminRequired = false
        } else {
          this.nomorKartuPenjaminRequired = true
        }
      },
      deep: true
    },
    visible(val) {
      this.modalVisible = val
      if (val) {
        this.init()
      }
    },
    'tujuan.selectedInstalation': {
      handler(data) {
        if (data && data.id) {
          this.fetchUnitInstalation()
        }
      },
      deep: true
    },
    'tujuan.selectedUnit': {
      handler(data) {
        if (data && data.id) {
          this.fetchRuangan()
        }
      },
      deep: true
    },
    'tujuan.selectedRuangan': {
      handler(data) {
        if (data && data.id) {
          // this.fetchSmf()
          this.fetchPaket()
          this.fetchDokter()
          if (
            this.penjamin.selectedKelasPenjamin &&
            this.penjamin.selectedKelasPenjamin.value
          ) {
            this.fetchTindakan()
          }
        }
      },
      deep: true
    },
    // 'tujuan.selectedSmf': {
    //   handler(data) {
    //     if (data && data.id) {
    //       this.fetchDokter()
    //     }
    //   },
    //   deep: true
    // },
    'kecelakaan.selectedProvinsi': {
      handler(data) {
        if (data && data.value) {
          this.fetchKabupaten()
        }
      },
      deep: true
    },
    'kecelakaan.selectedKabupaten': {
      handler(data) {
        if (data && data.value) {
          this.fetchKecamatan()
        }
      },
      deep: true
    },
    'tujuan.selectedKelasPenjamin': {
      handler(data) {
        if (data && data.value) {
          if (this.tujuan.selectedRuangan && this.tujuan.selectedRuangan.id) {
            this.fetchTindakan()
          }
        }
      },
      deep: true
    },
    'tujuan.kodeReservasi': {
      handler(data, old, v) {
        console.log({ data, old, v })
      },
      deep: true
    },
    'penjamin.selectedPenjamin': {
      handler() {
        this.setNoPenjamin()
      },
      deep: true
    },
  },
  beforeMount() {
    const { norm, nama } = this.$route.query
    if (norm && nama) {
      this.selectedPasien = { norm, nama }
    }
  },
  mounted() {
    this.$store.dispatch('masterdata/getInstalasi')
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.init()
    })
  },
  methods: {
    init() {
      this.instalations = this.arrayRuanganToLists(
        this.$store.getters['masterdata/ruanganInstalasi']
      )
      this.provinces = this.$store.getters['masterdata/provinsi']
      this.penjaminDefault()

      this.pasienError = null

      if (this.data) {
        this.detailPendaftaran(this.data)
      }

      if (this.pasien) {
        this.selectedPasien = this.pasien
        this.filteredPasiens = [this.pasien]
        this.debounceFetchRm()
      }

      if (this.$store.getters['masterdata/penjamin'].length < 1) {
        this.$store.dispatch('masterdata/getPenjamin')
      }

      if (this.$store.getters['masterdata/penjaminKelas'].length < 1) {
        this.$store.dispatch('masterdata/getPenjaminKelas')
      }
    },
    closeModal() {
      this.modalVisible = false
      this.$store.dispatch('app/removeOperation', ['createPasien'])
      this.$refs.panelRekamMedis.unsetData()
      this.$refs.panelKecelakaan.unsetData()
      this.$emit('closeModal', this.postedData)
    },
    penjaminDefault() {
      const penjamins = this.$store.getters['masterdata/penjamin']
      const penjaminKelas = this.$store.getters['masterdata/penjaminKelas']
      const nonKelasHak = penjaminKelas.filter((v) => v.title == 'Non Kelas')

      this.penjamin.selectedPenjamin = penjamins[0]

      if (nonKelasHak.length > 0) {
        this.penjamin.selectedKelasPenjamin = nonKelasHak[0]
      }
    },
    dateFormat(ctx) {
      this.dateDaftarFormatted = ctx.selectedFormatted
    },
    dateFormatRujukan(ctx) {
      this.rujukan.dateRujukanFormatted = ctx.selectedFormatted
    },
    dateFormatKecelakaan(ctx) {
      this.kecelakaan.dateKecelakaanFormatted = ctx.selectedFormatted
    },
    onSearchPkk(text) {
      if (text === '' || text === undefined) {
        return
      }

      const filteredData = this.pkkSuggest.data
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, 10)
      this.filteredPKKOptions = [
        {
          data: filteredData
        }
      ]
    },
    pkkSelected(i) {
      this.rujukan.pkkSelected = i.item
    },
    rujukanBaru() {
      this.rujukan.nomorRujukan = null
      this.rujukan.dateRujukan = this.$moment().format('YYYY-MM-DD')
    },
    onSearchPasien: debounce(async function (event) {
      const query = event.target.value
      if (query === '' || query === undefined) {
        return
      }

      try {
        this.search = query
        const res = await axiosIns.get(`/pasien/search/${query}`)
        this.filteredPasiens = [{
          data: res.data.data.map((pasien) => ({...pasien, formattedNama: `${pasien.norm} - ${pasien.nama}`}))
        }]
      } catch (err) {
        console.error(err)
      }
    }, 300),
    pasienSelected(i) {
      this.selectedPasien = i.item
      this.pasienError = null
      this.setNoPenjamin()
      this.debounceFetchRm()
    },
    async fetchRm() {
      try {
        const { data } = await axios.get(`/pasien/${this.selectedPasien.norm}/pendaftaran/recent`)
        const { medical_record, pasien } = data.data
        if (!medical_record) return;
        if (medical_record.berat_badan) this.rekamMedis.berat_badan = medical_record.berat_badan
        if (medical_record.tinggi_badan) this.rekamMedis.tinggi_badan = medical_record.tinggi_badan
        if (medical_record.lingkar_perut) this.rekamMedis.lingkar_perut = medical_record.lingkar_perut
        if (medical_record.berat_badan && medical_record.tinggi_badan) 
          this.rekamMedis.imt = (parseInt(medical_record.berat_badan) / (parseInt(medical_record.tinggi_badan) / 100) ** 2).toFixed(2)
        if (pasien.no_bpjs) this.tempNoBpjs = pasien.no_bpjs
      } catch (error) {
        console.error(error)
      }
    },
    debounceFetchRm: debounce(function () {
      this.fetchRm()
    }, 300),
    pasienSuggestionValue(suggestion) {
      return `${suggestion.item.norm} - ${suggestion.item.nama}`
    },
    async onSearchIcd(event) {
      const query = this.queryIcd
      if (query === '' || query === undefined || query.length < 5) {
        return
      }

      try {
        const { data } = await axios.get(`icd?search=${query}`)

        if (data.data.length > 0) {
          this.filteredIcds = [
            {
              data: data.data.map((icd) => ({
                id: icd.id,
                code: icd.CODE,
                str: icd.STR
              }))
            }
          ]
        }
      } catch (err) {
        this.toast('Tidak dapat memuat data ICD', 'danger', 'AlertTriangleIcon')
      }
    },
    icdSelected(i) {
      this.rujukan.selectedIcd = i.item
    },
    icdSuggestionValue(suggestion) {
      return `${suggestion.item.code} - ${suggestion.item.str}`
    },
    async fetchUnitInstalation() {
      if (!this.tujuan.selectedInstalation) {
        return
      }

      this.units = []
      this.tujuan.selectedUnit = null
      this.ruangans = []
      this.tujuan.selectedRuangan = null
      // this.smfs = []
      // this.tujuan.selectedSmf = null
      this.tujuan.pakets = []
      this.tujuan.selectedPaket = null
      this.dokters = []
      this.tujuan.selectedDokter = null
      this.tujuan.kodeReservasi = this.data?.tujuan_pasien?.kamar

      try {
        const { data } = await axios.get(
          `unit/ruangan/${this.tujuan.selectedInstalation.id}`
        )

        if (data.success === true && data.data.length > 0) {
          this.units = this.arrayRuanganToLists(data.data)

          if (data.data.length == 1) {
            this.tujuan.selectedUnit = this.units[0]
          }

          if (this.data) {
            this.tujuan.selectedUnit =
              this.units.find(
                (v) => (v.id = this.data.tujuan_pasien.unit.id)
              ) || null
          }
        }
      } catch (err) {
        this.toast('Tidak dapat memuat data unit', 'danger', 'AlertTriangleIcon')
      }
    },
    async fetchRuangan() {
      if (!this.tujuan.selectedUnit) {
        return
      }

      this.ruangans = []
      this.tujuan.selectedRuangan = null
      // this.smfs = []
      // this.tujuan.selectedSmf = null
      this.tujuan.pakets = []
      this.tujuan.selectedPaket = null
      this.dokters = []
      this.tujuan.selectedDokter = null
      this.tujuan.kodeReservasi = this.data?.tujuan_pasien?.kamar

      try {
        const { data } = await axios.get(
          `ruangan/unit/${this.tujuan.selectedUnit.id}`
        )

        if (data.success === true && data.data.length > 0) {
          // this.ruangans = this.arrayRuanganToLists(data.data)
          this.ruangans = data.data
            .map(({id, deskripsi:name, jenis_kunjungan}) => ({id, name, jenis_kunjungan}))
            .reduce((acc, curr) => {
              if (acc.find((v) => v.id === curr.id)) {
                return acc
              }
              acc.push(curr)
              return acc
            }, [])
          
          if (data.data.length === 1) {
            [this.tujuan.selectedRuangan] = this.ruangans
          }

          if (this.data) {
            this.tujuan.selectedRuangan =
              this.ruangans.find(
                (v) => (v.id === this.data.tujuan_pasien.ruangan.id)
              ) || null
          }
        }
      } catch (err) {
        this.toast('Tidak dapat memuat data ruangan', 'danger', 'AlertTriangleIcon')
      }
    },
    // async fetchSmf() {
    //   if (!this.tujuan.selectedRuangan) {
    //     return
    //   }

    //   this.smfs = []
    //   this.tujuan.selectedSmf = null
    //   this.dokters = []
    //   this.tujuan.selectedDokter = null

    //   try {
    //     const { data } = await axios.get(
    //       `ruangan/smf/${this.tujuan.selectedRuangan.id}`
    //     )

    //     if (data.success === true && data.data.length > 0) {
    //       // this.smfs = this.arrayToLists(data.data, [
    //       //   "master_ruangan",
    //       //   "deskripsi",
    //       // ]);
    //       this.smfs = data.data.map((v) => {
    //         if (v.referensi_smf)
    //           return {
    //             id: v.referensi_smf.value,
    //             name: v.referensi_smf.title
    //           }
    //       })

    //       if (data.data.length == 1) {
    //         this.tujuan.selectedSmf = this.smfs[0]
    //         // this.fetchDokter();
    //       }

    //       if (this.data) {
    //         this.tujuan.selectedSmf =
    //           this.smfs.find((v) => (v.id = this.data.tujuan_pasien.smf_id)) ||
    //           null
    //       }
    //     }
    //   } catch (err) {
    //     this.toast('Tidak dapat memuat data Spesialis', 'danger', 'AlertTriangleIcon')
    //   }
    // },
    async fetchDokter() {
      if (!this.tujuan.selectedRuangan) {
        return
      }

      this.dokters = []
      this.tujuan.selectedDokter = null
      this.tujuan.kodeReservasi = this.data?.tujuan_pasien?.kamar

      try {
        const { data } = await axios.get(
          `ruangan/dokter/${this.tujuan.selectedRuangan.id}`
        )

        if (data.success === true && data.data.length > 0) {
          this.dokters = data.data.map((data) => {
            if (!data.master_dokter || data.status == 0) return
            return {
              id: data.master_dokter.id,
              name: data.master_dokter.master_pegawai.nama_dokter,
              master_dokter_id: data.master_dokter.id
            }
          }).filter((v) => v)

          if (data.data.length == 1) {
            this.tujuan.selectedDokter = this.dokters[0]
          }

          if (this.data) {
            this.tujuan.selectedDokter =
              this.dokters.find(
                (v) => (v.master_dokter_id = this.data.tujuan_pasien.dokter_id)
              ) || null
          }
        }
      } catch (err) {
        console.error(err)
        this.toast('Tidak dapat memuat data dokter', 'danger', 'AlertTriangleIcon')
      }
    },
    async fetchKabupaten() {
      if (!this.kecelakaan.selectedProvinsi) {
        return
      }

      this.dristricts = []
      this.kecelakaan.selectedKecamatan = null
      this.kecelakaan.selectedKabupaten = null

      try {
        const { data } = await axios.get(
          `wilayah/kota/${this.kecelakaan.selectedProvinsi.value}?jenis=2`
        )

        if (data.success === true && data.data.length > 0) {
          this.cities = data.data

          if (this.data && this.data.kecelakaan.kode_kabupaten) {
            this.kecelakaan.selectedKabupaten =
              this.cities.find(
                (v) => (v.value = this.data.kecelakaan.kode_kabupaten)
              ) || null
          }
        }
      } catch (err) {
        this.toast('Tidak dapat memuat data kota/kabupaten',
          'danger', 'AlertTriangleIcon'
        )
      }
    },
    async fetchKecamatan() {
      if (!this.kecelakaan.selectedKabupaten) {
        return
      }

      try {
        const { data } = await axios.get(
          `wilayah/kecamatan/${this.kecelakaan.selectedKabupaten.value}?jenis=3`
        )

        if (data.success === true && data.data.length > 0) {
          this.dristricts = data.data

          if (this.data && this.data.kecelakaan.kode_kecamatan) {
            this.kecelakaan.selectedKecamatan =
              this.dristricts.find(
                (v) => (v.value = this.data.kecelakaan.kode_kecamatan)
              ) || null
          }
        }
      } catch (err) {
        this.toast('Tidak dapat memuat data kecamatan',
          'danger', 'AlertTriangleIcon'
        )
      }
    },
    async fetchPaket() {
      try {
        const { data } = await this.$axios.get(
          `package/by-ruangan/${this.tujuan.selectedRuangan.id}`
        )
        this.pakets = data.packages

        if (data.packages == 1) {
          this.tujuan.selectedPaket = this.pakets[0]
        }

        if (this.data) {
          this.tujuan.selectedPaket =
            this.pakets.find((v) => (v.id = this.data.paket_id)) ||
            null
        }
      } catch (err) {
        console.error(err)
        this.toast('Tidak dapat memuat data paket', 'danger', 'AlertTriangleIcon')
      }
    },
    async submitForm() {
      if (!this.selectedPasien) {
        this.pasienError = 'Pasien belum dipilih'
        return
      }

      this.$refs.PendaftaranKunjungan.validate().then((success) => {
        if (success) {
          this.showDialogConfirmation = true
        }
      })
    },
    resetForm() {
      this.selectedPasien = null
      Object.keys(this.tujuan).forEach((index) => {
        this.tujuan[index] = null
      })
      Object.keys(this.rujukan).forEach((index) => {
        this.rujukan[index] = null
      })
      Object.keys(this.penjamin).forEach((index) => {
        this.penjamin[index] = null
      })
      this.penjaminDefault()
      Object.keys(this.kecelakaan).forEach((index) => {
        this.kecelakaan[index] = null
      })
      Object.keys(this.tanggungJawab).forEach((index) => {
        this.tanggungJawab[index] = null
      })
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onCancelSubmit() {
      this.showDialogConfirmation = false
    },
    onConfirmSubmit(daftarkanSatuSehat) {
      this.counterProgress = 1
      this.processingSubmit = true

      const tindakans = []
      if (this.selectedTindakans.length > 0) {
        this.selectedTindakans.forEach((item, index, arr) => {
          tindakans.push(index)
        })
      }
      // Simulate an async request
      const form = {
        norm: this.selectedPasien.norm,
        // tanggal_pendaftaran: this.dateDaftar + ' ' + this.timeDaftar + ':00',
        tanggal_pendaftaran: this.dateTimeDaftar,
        oleh: 1,
        tujuan: {
          ruangan_id: this.tujuan.selectedRuangan.id,
          // smf_id: this.tujuan.selectedSmf.id,
          dokter_id: this.tujuan.selectedDokter.master_dokter_id,
          paket_id: this.tujuan?.selectedPaket ? this.tujuan.selectedPaket.id : null,
          kamar_id: this.tujuan?.kodeReservasi ? this.tujuan.kodeReservasi.id : null,
        },
        rujukan: {
          ppk: this.rujukan.pkkSelected ? this.rujukan.pkkSelected.id : null,
          nomor: this.rujukan.nomorRujukan,
          tanggal: this.rujukan.dateRujukan,
          dokter: this.rujukan.dokter,
          bagian_dokter: this.rujukan.smf,
          diagnosa_masuk: this.rujukan.diagnosa
            ? this.rujukan.diagnosa.id
            : null,
          icd: this.rujukan.selectedIcd ? this.rujukan.selectedIcd.id : null
        },
        penjamin: {
          jenis: this.penjamin.selectedPenjamin.value,
          nomor_penjamin: this.penjamin.nomorKartuPenjamin,
          kelas: this.penjamin.selectedKelasPenjamin.value,
          no_sep: this.penjamin.nomorSEP,
          keterangan: this.penjamin.keterangan
        },
        kecelakaan: this.kecelakaan?.status ? this.kecelakaan : null,
        penanggung_jawab_pasien: {
          hubungan: this.tanggungJawab.selectedPasienRelation
            ? this.tanggungJawab.selectedPasienRelation.value
            : null,
          nama: this.tanggungJawab.nama,
          jenis_kelamin: this.tanggungJawab.jkSelected
            ? this.tanggungJawab.jkSelected.value
            : null,
          pendidikan: this.tanggungJawab.pendidikanSelected
            ? this.tanggungJawab.pendidikanSelected.value
            : null,
          pekerjaan: this.tanggungJawab.pekerjaanSelected
            ? this.tanggungJawab.pekerjaanSelected.value
            : null,
          alamat: this.tanggungJawab.alamat,
          jenis_kontak: this.tanggungJawab.jenisKontakSelected
            ? this.tanggungJawab.jenisKontakSelected.value
            : null,
          no_kontak: this.tanggungJawab.telp
        },
        medical_record: {
          ...this.rekamMedis,
          sistole: this.rekamMedis.sistole || this.normalRm().sistole,
          diastole: this.rekamMedis.diastole || this.normalRm().diastole,
          respiratory_rate: this.rekamMedis.resp_rate || this.normalRm().resp_rate,
          heart_rate: this.rekamMedis.heart_rate || this.normalRm().heart_rate,
        },
        tindakan_ids: tindakans,
        satu_sehat: daftarkanSatuSehat,
      }

      const { data } = axios
        .post('pendaftaran', form, {
          onUploadProgress: function (progressEvent) {
            this.counterProgress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            )
          }.bind(this)
        })
        .then((res) => {
          this.counterProgress = 1
          this.processingSubmit = false
          this.showDialogConfirmation = false

          if (res.data.data && Object.keys(res.data.data).length > 0) {
            this.$bvModal.hide(this.modalId)
            this.postedData = res.data.data
            this.$emit('finish', this.selectedPasien.norm, res.data.data)
            this.resetForm()
            this.toast('Pasien sukses didaftarkan antrean kunjungan', 'success', 'MessageCircleIcon')
          }
        })
        .catch((err) => {
          this.counterProgress = 1
          this.processingSubmit = false
          this.showDialogConfirmation = false

          if (
            err.response &&
            err.response.data &&
            Object.keys(err.response.data.message).length > 0
          ) {
            for (const errors of Object.values(err.response?.data?.errors || {})) {
              for (const error of errors) {
                this.toast(error, 'danger', 'AlertTriangleIcon')
              }
            }
            if (!err.response.data?.errors && err.response.data?.message) this.toast(err.response.data?.message, 'danger', 'AlertTriangleIcon')
            if (!err.response.data?.errors && !err.response.data?.message) this.toast('Harap lengkapi isian data kunjungan', 'danger', 'MessageCircleIcon')
          } else {
            this.toast('Gagal mendaftarkan kunjungan baru. Harap hubungi tim IT',
              'danger', 'AlertTriangleIcon'
            )
          }
        })
    },
    normalRm() {
      if (this.age < 5) {
        return {
          sistole: this.rand(95, 110),
          diastole: this.rand(56, 70),
          resp_rate: this.rand(24, 40),
          heart_rate: this.rand(80, 120),
        }
      }
      if (this.age < 13) {
        return {
          sistole: this.rand(97, 112),
          diastole: this.rand(57, 71),
          resp_rate: this.rand(16, 30),
          heart_rate: this.rand(75, 118),
        }
      }
      if (this.age < 18) {
        return {
          sistole: this.rand(112, 128),
          diastole: this.rand(66, 80),
          resp_rate: this.rand(12, 20),
          heart_rate: this.rand(60, 100),
        }
      }
      return {
        sistole: this.rand(90, 120),
        diastole: this.rand(60, 80),
        resp_rate: this.rand(12, 20),
        heart_rate: this.rand(60, 100),
      }
    },
    rand(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    detailPendaftaran(data) {
      if (!data?.tujuan_pasien) return;
      this.tujuan.selectedInstalation =
        this.instalations.find(
          (v) => v.id == data.tujuan_pasien.instalasi.id
        ) || null

      const datetime = data.tanggal_pendaftaran
      const dateRujukan = data.rujukan?.tanggal || new Date()

      this.dateDaftar = this.$moment(datetime).format('YYYY-MM-DD')
      this.timeDaftar = this.$moment(datetime).format('HH:mm')
      this.selectedPasien = data.pasien

      this.rujukan = {
        pkkSelected: data.rujukan?.ppk
          ? data.rujukan.ppk.deskripsi || null
          : null,
        nomorRujukan: data.rujukan?.nomor,
        dateRujukan: this.rujukan?.pkkSelected
          ? this.$moment(data.rujukan?.tanggal).format('YYYY-MM-DD')
          : null,
        dateRujukanFormatted: null,
        dokter: data.rujukan?.dokter,
        smf: data.rujukan?.smf,
        diagnosa: data.rujukan?.diagnosa,
        selectedIcd: data.rujukan?.icd
          ? this.icd.find((v) => v.id == data.rujukan.id) || null
          : null
      }

      this.penjamin = {
        selectedPenjamin:
          this.$store.getters['masterdata/penjamin'].find(
            (v) => v.value == data.penjamin.jenis
          ) || null,
        nomorKartuPenjamin: data.penjamin.nomor_penjamin,
        selectedKelasPenjamin:
          this.$store.getters['masterdata/penjaminKelas'].find(
            (v) => v.value == data.penjamin.kelas
          ) || null,
        nomorSEP: data.penjamin.no_sep,
        keterangan: data.penjamin.keterangan
      }

      if (data.penanggung_jawab_pasien) {
        this.tanggungJawab.enabled = true
      }

      this.tanggungJawab = {
        selectedPasienRelation:
          this.$store.getters['masterdata/shdk'].find(
            (v) => v.value == data.penanggung_jawab_pasien?.hubungan
          ) || null,
        nama: data.penanggung_jawab_pasien?.nama,
        jkSelected:
          this.$store.getters['masterdata/jk'].find(
            (v) => v.value == data.penanggung_jawab_pasien?.jenis_kelamin
          ) || null,
        pendidikanSelected:
          this.$store.getters['masterdata/pendidikan'].find(
            (v) => v.value == data.penanggung_jawab_pasien?.pendidikan
          ) || null,
        pekerjaanSelected:
          this.$store.getters['masterdata/pekerjaan'].find(
            (v) => v.value == data.penanggung_jawab_pasien?.pekerjaan
          ) || null,
        alamat: data.penanggung_jawab_pasien?.alamat,
        telp: data.penanggung_jawab_pasien?.no_kontak,
        jenisKontakSelected:
          this.$store.getters['masterdata/jenisKontak'].find(
            (v) => v.value == data.penanggung_jawab_pasien?.jenis_kontak
          ) || null
      }

      if (data.kecelakaan) this.kecelakaan = {
        jenis_kecelakaan: data.kecelakaan.jenis_kecelakaan,
        no_laporan_polisi: data.kecelakaan.no_laporan_polisi,
        penjamin: data.kecelakaan.penjamin,
        tanggal_kejadian: data.kecelakaan.tanggal_kejadian
          ? this.$moment(data.kecelakaan.tanggal_kejadian).format('YYYY-MM-DD')
          : null,
        suplesi: !!data.kecelakaan.suplesi,
        no_sep_suplesi: data.kecelakaan.no_sep_suplesi,
        kode_provinsi: data.kecelakaan.kode_provinsi,
        kode_kabupaten: data.kecelakaan.kode_kabupaten,
        kode_kecamatan: data.kecelakaan.kode_kecamatan,
        status: !!data.kecelakaan.status,
        keterangan: data.kecelakaan.keterangan,
      }

      this.rekamMedis.keluhan = data.medical_record.keluhan
      this.rekamMedis.tinggi_badan = data.medical_record.tinggi_badan
      this.rekamMedis.berat_badan = data.medical_record.berat_badan
      this.rekamMedis.lingkar_perut = data.medical_record.lingkar_perut
      this.rekamMedis.imt = data.medical_record.imt
      this.rekamMedis.sistole = data.medical_record.sistole
      this.rekamMedis.diastole = data.medical_record.diastole
      this.rekamMedis.resp_rate = data.medical_record.respiratory_rate
      this.rekamMedis.heart_rate = data.medical_record.heart_rate
    },
    async fetchTindakan() {
      try {
        const { data } = await axios.get(
          `ruangan/tindakan/${this.tujuan.selectedRuangan.id}/${this.penjamin.selectedKelasPenjamin.value}`
        )

        if (data.success === true && data.data.length > 0) {
          const tindakans = data.data
            .filter((v) => v?.master_tindakan?.master_tarif_tindakan?.length)
            .map((v) => {
              if (
                v.master_tindakan &&
                v.master_tindakan.master_tarif_tindakan.length > 0
              ) {
                return {
                  id: v.id,
                  name: v.nama_tindakan,
                  price: v.master_tindakan.master_tarif_tindakan[0].tarif,
                  formattedPrice: new Intl.NumberFormat('id-ID', {
                    maximumSignificantDigits: 3
                  }).format(v.master_tindakan.master_tarif_tindakan[0].tarif)
                }
              }
            })

          this.tindakans = tindakans
        }
      } catch (err) {
        console.error(err)
        this.toast('Tidak dapat memuat data tindakan ruangan',
          'danger', 'AlertTriangleIcon'
        )
      }
    },
    setNoPenjamin() {
      if (this.penjamin?.selectedPenjamin?.value !== 2) return
      if (!this.selectedPasien.no_bpjs) return
      this.penjamin.nomorKartuPenjamin = this.tempNoBpjs || this.selectedPasien.no_bpjs
    },
    async openModalBpjsNoka() {
      if (!this.penjamin?.nomorKartuPenjamin) return;
      try {
        const { data } = await axios.get(`/pasien/ref_bpjs/noka/${this.penjamin.nomorKartuPenjamin}`)
        if (!this.bpjsToast(data.metadata)) return;
        this.parseBpjsPasien(data.body)
        this.bpjsPasienResponse.method = 'NOKA'
        this.pasienBpjsVisible = true
      } catch (error) {
        console.error(error)
        if (error?.response?.data?.message) {
          this.toast(error?.response?.data?.message, 'danger', 'AlertTriangleIcon')
        }
      }
    },
    async openModalBpjsNik() {
      if (!this.selectedPasien?.nik) return;
      try {
        const { data } = await axios.get(`/pasien/ref_bpjs/nik/${this.selectedPasien.nik}`)
        if (!this.bpjsToast(data.metadata)) return;
        this.parseBpjsPasien(data.body)
        this.bpjsPasienResponse.method = 'NIK'
        this.pasienBpjsVisible = true;
      } catch (error) {
        console.error(error)
        if (error?.response?.data?.message) {
          this.toast(error?.response?.data?.message, 'danger', 'AlertTriangleIcon')
        }
      }
    },
    parseBpjsPasien(data) {
      this.bpjsPasienResponse.noKartu = data?.noKartu || ''
      this.bpjsPasienResponse.nik = data?.noKTP || ''
      this.bpjsPasienResponse.nama = data?.nama || ''
      this.bpjsPasienResponse.hubunganKeluarga = data?.hubunganKeluarga || ''
      this.bpjsPasienResponse.sex = data?.sex || ''
      this.bpjsPasienResponse.tglLahir = data?.tglLahir || ''
      this.bpjsPasienResponse.tglMulaiAktif = data?.tglMulaiAktif || ''
      this.bpjsPasienResponse.tglAkhirBerlaku = data?.tglAkhirBerlaku || ''
      this.bpjsPasienResponse.provider = data?.kdProviderPst?.nmProvider || ''
      this.bpjsPasienResponse.providerGigi = data?.kdProviderGigi?.nmProvider || ''
      this.bpjsPasienResponse.kelas = data?.jnsKelas?.nama || ''
      this.bpjsPasienResponse.jenisPeserta = data?.jnsPeserta?.nama || ''
      this.bpjsPasienResponse.golDarah = data?.golDarah || ''
      this.bpjsPasienResponse.noHp = data?.noHP || ''
      this.bpjsPasienResponse.statusAktif = data?.ketAktif || ''
      this.bpjsPasienResponse.tunggakan = data?.tunggakan || 0
      this.bpjsPasienResponse.aktif = data?.aktif

      const kelas = this.$store.getters['masterdata/penjaminKelas'].find(x => `${x.title}`.toLowerCase() === `${data?.jnsKelas?.nama}`.toLowerCase())
      this.penjamin.selectedKelasPenjamin = kelas
    },
    selectBed(bed) {
      if (bed.status != 1) return
      this.tujuan.kodeReservasi = bed
      this.$root.$emit('bv::hide::modal', 'reservasi-bedpanel-modal')
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.modal-tambah-kunjungan .tab-content {
  height: 100%;
}
</style>
